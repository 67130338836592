import { useDialog } from 'core/providers/DialogProvider';
import React from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog, {
  ISharedCustomerListDialogRow,
} from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';

export interface IReportBreakdownCustomCellBreakdown {
  key: string;
  count: number;
  label: string;
  customerListRows: ISharedCustomerListDialogRow[];
  breakdowns?: IReportBreakdownCustomCellBreakdown[];
}

export interface IReportBreakdownCustomCell {
  label: string;
  count: number;
  breakdowns: IReportBreakdownCustomCellBreakdown[];
}

const ReportBreakdownCustomCell = (data: IReportBreakdownCustomCell) => {
  const { label, count, breakdowns } = data;
  return (
    <div className='grid grid-cols-2 gap-x-4 gap-y-1'>
      <p className='body-01 mb-4'>{label}</p>
      <p className='body-01 mb-4'>{count}</p>
      <ReportBreakdownCustomCellBreakdowns breakdowns={breakdowns} total={count} />
    </div>
  );
};

export default ReportBreakdownCustomCell;

interface IReportBreakdownCustomCellBreakdowns {
  breakdowns: IReportBreakdownCustomCellBreakdown[];
  total: number;
  className?: string;
}

const ReportBreakdownCustomCellBreakdowns = ({
  breakdowns,
  total,
  className,
}: IReportBreakdownCustomCellBreakdowns) => {
  const dialog = useDialog();
  return (
    <>
      {breakdowns
        .filter((breakdown) => breakdown.count !== 0)
        .map((breakdown) => (
          <React.Fragment key={breakdown.key}>
            <p className={className}>{breakdown.label}</p>
            <div className={className}>
              <SharedButton
                onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={breakdown.customerListRows!} />)}
                type='button'
                spacing='none'
                appearance='link'
                label={`${breakdown.count}   (${((breakdown.count / total) * 100).toFixed(2)}%)`}
              />
            </div>
            {breakdown.breakdowns && (
              <>
                <ReportBreakdownCustomCellBreakdowns
                  breakdowns={breakdown.breakdowns}
                  total={breakdown.count}
                  className='text-xs'
                />
                <div />
                <div className='mb-2' />
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
};
